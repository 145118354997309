import { ChangeDetectionStrategy, Component, booleanAttribute, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Address } from 'forms-data';
import { provideValueAccessor } from '../../base/provideValueAccessor';
import { JsonPipe } from '@angular/common';
import { booleanOrUndefinedAttribute } from 'common-util';
import { AbstractFormControl, MatFormFieldAdaptorDirective } from '../../base';
import { AddressFormComponent } from '../address-form/address-form.component';
import { AddressSearchComponent } from '../address-search/address-search.component';

@Component({
  selector: 'ideal-address-search-form',
  standalone: true,
  imports: [AddressSearchComponent, AddressFormComponent, ReactiveFormsModule, JsonPipe],
  templateUrl: './address-search-form.component.html',
  styleUrl: './address-search-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(AddressSearchFormComponent)],
  hostDirectives: [MatFormFieldAdaptorDirective],
})
export class AddressSearchFormComponent extends AbstractFormControl<Address | null> {
  public controlType = 'address';

  readonly maxLengthLine1 = input(30);
  readonly maxLengthLine2 = input(30);
  readonly maxLengthLocality = input(20);

  readonly coordinatesEnabled = input(false, { alias: 'coordinates', transform: booleanAttribute });
  readonly coordinatesRequired = input(undefined, { transform: booleanOrUndefinedAttribute });
  readonly coordinatesHidden = input(undefined, { transform: booleanOrUndefinedAttribute });

  protected onAddressSelected(address: Address | null): void {
    this.writeValue(address);
  }

  constructor() {
    super();
    this.setControl(new FormControl<Address | null>(null));
  }
}
