import { ChangeDetectorRef, Directive, Injector, Input, computed, effect, inject, input, signal, untracked } from '@angular/core';
import { outputFromObservable, takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import { EMPTY, Observable, delay, filter, map, switchMap } from 'rxjs';
import { AbstractControlWrap } from './AbstractControlWrap';
import { IFormControl } from './IFormControl';

@Directive()
export abstract class AbstractFormControlBase<
  TValue = unknown,
  TControl extends FormGroup<AbstractControlWrap<TValue>> | FormControl<TValue> =
    | FormGroup<AbstractControlWrap<TValue>>
    | FormControl<TValue>,
> implements IFormControl<TValue, TControl>
{
  readonly #changeRef = inject(ChangeDetectorRef);
  #injector = inject(Injector);

  ngControl: NgControl | null = null;

  readonly #externalControl = signal<AbstractControl<TValue> | undefined>(undefined);

  readonly #externalControlEvents = toSignal(
    toObservable(this.#externalControl).pipe(
      filter((c) => !!c),
      switchMap((c) => c!.events),
      delay(0), // fixes bug with angular not allowing signal writes here for some reason
      takeUntilDestroyed(),
    ),
  );

  #onTouchCallback: () => void = () => {};
  #onValidatorChangeCallback: () => void = () => {};

  readonly #control = signal<TControl | undefined>(undefined);
  readonly control = this.#control.asReadonly();
  protected readonly control$ = toObservable(this.control);

  @Input()
  get valueInput(): TValue | undefined {
    return this.value;
  }

  set valueInput(v: TValue) {
    if (!this.equals(v)) {
      this.writeValue(v);
    }
  }

  get value(): TValue | undefined {
    return untracked(this.control)?.value as TValue | undefined;
  }

  protected setControl(control: TControl): void {
    this.#control.set(control);
  }

  readonly requiredInput = input<boolean | undefined>(undefined, { alias: 'required' });

  protected readonly requiredExternal = computed(() => {
    this.#externalControlEvents();
    return this.#externalControl()?.hasValidator(Validators.required) ?? false;
  });

  readonly required = computed(() => this.requiredInput() || this.requiredExternal());

  readonly disabledInput = input<boolean | undefined>(undefined, { alias: 'disabled' });
  protected readonly disabledInternal = toSignal(
    this.control$.pipe(
      switchMap((c) => c?.statusChanges ?? EMPTY),
      map((s) => s === 'DISABLED'),
    ),
  );
  readonly disabled = computed(() => this.disabledInput() ?? this.disabledInternal() ?? false);

  protected readonly valueChanges$ = this.control$.pipe(switchMap((c) => c?.valueChanges ?? EMPTY)) as Observable<TValue>;
  readonly valueChangeOutput = outputFromObservable(this.valueChanges$, { alias: 'valueChange' });
  readonly valueChanges = toSignal(this.valueChanges$);

  protected readonly statusChanges$ = this.control$.pipe(
    switchMap((c) => c?.statusChanges ?? EMPTY),
    takeUntilDestroyed(),
  );
  readonly statusChangeOutput = outputFromObservable(this.statusChanges$, { alias: 'statusChange' });
  readonly statusChanges = toSignal(this.statusChanges$, { initialValue: 'VALID' });

  writeValue(value: TValue): void {
    untracked<AbstractControl | undefined>(this.control)?.patchValue(this.isEmpty(value) ? null : value);
  }

  registerOnChange(fn: any): void {
    this.valueChanges$.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.#onTouchCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      untracked(this.control)?.disable();
    } else {
      untracked(this.control)?.enable();
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.#onValidatorChangeCallback = fn;
  }

  protected markAsTouched(): void {
    untracked(this.control)?.markAsTouched();
    this.#onTouchCallback();
  }

  protected validatorChange(): void {
    this.#onValidatorChangeCallback();
  }

  protected isEmpty(v: TValue): boolean {
    return v === null || v === undefined;
  }

  empty = computed(() => this.isEmpty((this.control() as AbstractControl)?.value));
  focused = signal(false);

  protected isEqual(a: TValue, b: TValue): boolean {
    return a === b;
  }

  equals(value: TValue): boolean {
    return this.isEqual((untracked(this.control) as AbstractControl)?.value, value);
  }

  protected markForCheck(): void {
    this.#changeRef.markForCheck();
  }

  async ngOnInit() {
    await Promise.resolve();
    this.ngControl = this.#injector.get(NgControl, null, { self: true });
    this.#externalControl.set(this.ngControl?.control ?? undefined);
  }

  constructor() {
    effect(() => {
      const required = this.required();
      const control = this.control();

      if (control) {
        if (required) {
          control.addValidators([Validators.required]);
        } else {
          control.removeValidators([Validators.required]);
        }
        control.updateValueAndValidity();
        this.validatorChange();
      }
    });
  }
}
