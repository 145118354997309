import { DOCUMENT, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  effect,
  inject,
  input,
  model,
  output,
  TemplateRef,
  untracked,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, FormGroupDirective, FormResetEvent, FormSubmittedEvent, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DialogComponent } from 'common-ui';
import { fromEvent, map } from 'rxjs';
import { FormButtonGroupComponent, FormComponent } from '../../common';

@Component({
  selector: 'ideal-edit-item-toolbar',
  standalone: true,
  imports: [DialogComponent, NgTemplateOutlet, MatButtonModule, ReactiveFormsModule, FormButtonGroupComponent, FormComponent],
  templateUrl: './edit-item-toolbar.component.html',
  styleUrl: './edit-item-toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditItemToolbarComponent {
  private readonly _document = inject(DOCUMENT);
  private readonly _dialogCmp = viewChild.required(DialogComponent);
  private readonly _formComponent = viewChild.required<FormComponent>('form');
  private readonly _screenHeight = toSignal(fromEvent(window, 'resize').pipe(map(() => this._document.documentElement.clientHeight)), {
    initialValue: this._document.documentElement.clientHeight,
  });

  public readonly editTemplate = contentChild.required<TemplateRef<any>>('edit');
  public readonly formGroup = input.required<FormGroup>();
  public readonly open = model<boolean>(false);

  public readonly newLabel = input<string>('New');
  public readonly submitLabel = input<string>('Submit');
  public readonly cancelLabel = input<string>('Cancel');
  public readonly newClick = output<Event>();
  public readonly formReset = output<FormResetEvent>();
  public readonly formSubmitted = output<FormSubmittedEvent>();

  protected readonly displayInline = computed(() => {
    const dialogHeight = this._dialogCmp().contentHeight();
    const screenHeight = this._screenHeight();
    const inline = dialogHeight / screenHeight <= 0.5;
    untracked(this.formGroup).updateValueAndValidity();
    return inline;
  });

  protected onNewClicked() {
    const clickEvent = new Event('new-click', { cancelable: true });
    this.newClick.emit(clickEvent);
    if (!clickEvent.defaultPrevented) {
      this.formGroup().reset();
      this.open.set(true);
    }
  }

  public focus() {
    untracked(this._formComponent).focus();
  }

  public focusError() {
    untracked(this._formComponent).focusError();
  }

  constructor() {
    // require that a formGroup be provided
    inject(FormGroupDirective, { self: true });

    effect((onCleanup) => {
      const sub = this.formGroup().events.subscribe((event) => {
        if (event instanceof FormResetEvent) {
          this.open.set(false);
          this.formReset.emit(event);
        } else if (event instanceof FormSubmittedEvent) {
          this.formSubmitted.emit(event);
        }
      });
      onCleanup(() => sub.unsubscribe());
    });

    effect(() => {
      const open = this.open();
      const displayInline = untracked(this.displayInline);
      const formComponent = untracked(this._formComponent);
      if (displayInline && open) {
        // focus on the first input
        setTimeout(() => {
          formComponent.focus();
        });
      }
    });
  }
}
