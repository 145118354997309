import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  computed,
  contentChildren,
  DestroyRef,
  Directive,
  effect,
  inject,
  Injector,
  input,
  model,
  signal,
  untracked,
} from '@angular/core';
import { ControlEvent, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatError } from '@angular/material/form-field';

@Directive()
export abstract class AbstractFormField2<TModel, TView = TModel> {
  public readonly valueInput = model<TModel | undefined>(undefined, { alias: 'value' });

  // private readonly valueInputEffect = effect(() => this.setViewModel(this.valueInput()));

  protected setViewModel(model: TModel) {}
  protected setModel(viewModel: TView) {}

  constructor() {}
}

@Directive()
export abstract class AbstractFormField<TData = any, TControl extends FormControl<TData> = FormControl<TData>>
  implements ControlValueAccessor
{
  public readonly valueInput = model<TData | undefined>(undefined, { alias: 'value' });

  protected abstract createDefaultControl(): TControl;

  public writeValue(value: any): void {
    this.setViewModel(value);
    this.setInputModel(value);
  }

  public registerOnChange(fn: any): void {}
  public registerOnTouched(fn: any): void {}
  public setDisabledState?(isDisabled: boolean): void {}

  protected readonly destroyRef = inject(DestroyRef);
  private readonly _changeRef = inject(ChangeDetectorRef);
  private readonly _injector = inject(Injector);
  private readonly _initialized = signal<boolean>(false);
  private readonly _globalClick = signal<boolean>(false);
  private readonly _document = inject(DOCUMENT);
  protected readonly ngControl = signal<NgControl | null>(null);
  protected readonly control = signal<TControl>(this.createDefaultControl() as TControl);

  public readonly formControlName = input<string>();
  public readonly name = input<string>();
  private readonly _matErrors = contentChildren<MatError>(MatError);
  protected readonly hasContentErrors = computed(() => this._matErrors().length > 0);

  protected readonly fieldName = computed(() => this.name() ?? this.formControlName() ?? '');
  protected readonly autoComplete = computed(() => (this._globalClick() ? this.fieldName() : 'one-time-code'));

  constructor() {
    let firstValueUpdate = true;
    effect(() => {
      const value = this.valueInput();
      if (firstValueUpdate) {
        firstValueUpdate = false;
        return;
      }
      this.setModel(value);
      this.setInputModel(value);
    });

    /*;
    console.log('AbstractFormField.NgControl', this._injector.get(forwardRef(()=> NgControl), null, { self: true, optional: true }));
    let eventSub: Subscription = new Subscription();
    effect(() => {
      eventSub.unsubscribe();
      eventSub = this.control()
        .events.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((event) => this.onControlEvent(event));
    });

    let firstChange = true;
    effect(() => {
      const _initialized = this._initialized();
      if (!_initialized) return;

      const ngControl = untracked(this.ngControl);
      const control = untracked(this.control);
      const value = this.value() ?? control.defaultValue;


      this._changeRef.markForCheck();
      if (!firstChange || !ngControl) {
        if (control.value !== value) {
          console.log('AbstractFormField.effect control.setValue', value);
          control.setValue(value);
        }
        this.ngControl()?.viewToModelUpdate(value);
      }
      firstChange = false;
    });*/
  }

  async ngOnInit() {
    await Promise.resolve();
    const ctrl = this._injector.get(NgControl, null, { self: true, optional: true });
    if (ctrl) {
      ctrl.valueAccessor = this;
    }
    this.ngControl.set(ctrl);
    if (ctrl?.control) {
      this.control.set(ctrl.control as TControl);
    }
    this._initialized.set(true);

    const clickFn = () => {
      this._globalClick.set(true);
      this._document.removeEventListener('click', clickFn);
    };
    this._document.addEventListener('click', clickFn);
  }

  protected onControlEvent(controlEvent: ControlEvent<TData>) {
    /*if (controlEvent instanceof ValueChangeEvent) {
      console.log('AbstractFormField controlEvent.set', controlEvent);
      this.value.set(controlEvent.value);
      this._changeRef.markForCheck();
    }*/
  }

  protected setViewModel(value?: TData) {
    this._changeRef.markForCheck();
  }

  protected setInputModel(value?: TData) {
    if (untracked(this.valueInput) === value) return;
    this.valueInput.set(value);
  }

  protected setModel(value?: TData) {
    const control = untracked(this.control);
    value ??= control.defaultValue;
    if (control.value !== value) {
      // console.log('AbstractFormField.setModel control.setValue', `'${value}'`);
      control.setValue(value);
    }
  }
}
