import { Component, computed, inject, input, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validator } from '@angular/forms';
import { MatOption, MatSelect } from '@angular/material/select';
import { AddressService } from 'forms-data';
import { AbstractFormControl, MatFormFieldAdaptorDirective } from '../../base';
import { provideValueAccessor } from '../../base/provideValueAccessor';

@Component({
  selector: 'ideal-country-select',
  standalone: true,
  imports: [MatSelect, MatOption, ReactiveFormsModule],
  templateUrl: './country-select.component.html',
  styleUrl: './country-select.component.scss',
  providers: [provideValueAccessor(CountrySelectComponent)],
  hostDirectives: [MatFormFieldAdaptorDirective],
})
export class CountrySelectComponent extends AbstractFormControl<string | null> implements Validator {
  public controlType = 'country-select';

  readonly #addressService = inject(AddressService);

  readonly placeholder = input<string | undefined>(undefined);
  readonly shouldLabelFloat = signal(true);

  protected readonly countries = computed(() => {
    return this.#addressService.getCountryCodes().map((code) => ({
      code,
      name: this.#addressService.getCountryName(code),
    }));
  });

  constructor() {
    super();
    this.setControl(new FormControl<string | null>(null));
  }
}
