@if (contact(); as contact) {
  @if (contact.name) {
    <label class="name" i18n="@@contact.name">Name</label>
    <div class="name">{{ contact.name | titlecase }}</div>
  }
  @if (contact.company) {
    <label class="company" i18n="@@contact.company">Company</label>
    <div class="company">{{ contact.company | titlecase }}</div>
  }
  <ideal-address [address]="contact.address" [layout]="layout()"></ideal-address>

  @if (contact.email) {
    <label class="email" i18n="@@contact.email">Email</label>
    <div class="email">{{ contact.email | lowercase }}</div>
  }

  @if (contact.phone) {
    <label class="phone always" i18n="@@contact.phoneNumber"
      >Phone
      <div class="phone">{{ contact.phone }}</div></label
    >
  }

  @if (contact.tollFree) {
    <label class="tollFree always" i18n="@@contact.tollFree"
      >Toll Free
      <div class="tollFree">{{ contact.tollFree }}</div></label
    >
  }

  @if (contact.fax) {
    <label class="fax always" i18n="@@contact.fax"
      >Fax
      <div class="fax">{{ contact.fax }}</div></label
    >
  }
}
