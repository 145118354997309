@let ctrl = control();
@let iCtrl = internalControl();

<mat-form-field>
  <mat-label>{{ label() }}</mat-label>
  <input
    #field
    matInput
    type="text"
    inputmode="decimal"
    [autocomplete]="autoComplete()"
    [formControl]="iCtrl"
    [name]="fieldName()"
    [placeholder]="placeholder()"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (input)="onInput($event)"
    (keypress)="onKeypress($event)"
    (wheel)="onMousewheel($event)"
    (keydown)="onKeyDown($event)"
  />
  @if (ctrl.hasError("required")) {
    <mat-error>{{ label() }} is required</mat-error>
  }

  @let multipleOfErr = ctrl.getError("multipleOf");
  @if (multipleOfErr) {
    <mat-error
      >Must be a multiple of {{ multipleOfErr.multiple | number: format() }}. Closest:
      <a class="fix-value" (click)="set(multipleOfErr.snappedValue)">{{ multipleOfErr.snappedValue | number: format() }}</a></mat-error
    >
  }

  @let minErr = ctrl.getError("min");
  @let maxErr = ctrl.getError("max");
  <mat-hint align="start" [class.invalid]="minErr || maxErr || multipleOfErr">{{ startHint }}</mat-hint>
  <mat-hint align="end">{{ endHint }}</mat-hint>

  <!-- hint content projection -->
  <ng-content select="mat-hint:not([align='end'])" ngProjectAs="mat-hint:not([align='end'])"></ng-content>
  <ng-content select="mat-hint[align='end']" ngProjectAs="mat-hint[align='end']"></ng-content>

  <!-- prefix and suffix content projection -->
  <ng-content select="[matPrefix]" ngProjectAs="[matPrefix]"></ng-content>
  <ng-content select="[matIconPrefix]" ngProjectAs="[matIconPrefix]"></ng-content>
  <ng-content select="[matSuffix]" ngProjectAs="[matSuffix]"></ng-content>
  <ng-content select="[matIconSuffix]" ngProjectAs="[matIconSuffix]"></ng-content>
  <ng-content select="[matTextPrefix]" ngProjectAs="[matTextPrefix]"></ng-content>
  <ng-content select="[matTextSuffix]" ngProjectAs="[matTextSuffix]"></ng-content>

  <!-- error content projection -->
  <ng-content select="mat-error" ngProjectAs="mat-error"></ng-content>
  <ng-content select="[matError]" ngProjectAs="[matError]"></ng-content>
  <!-- this makes content projection for errors work -->
  @if (hasContentErrors()) {
    <mat-error></mat-error>
  }
</mat-form-field>

<!-- <pre>
  ierrors: {{ internalControl.errors | json }}
  errors: {{ ctrl.errors | json }}
  value: {{ ctrl.value | json }}

</pre> -->
