import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, Input, Output, computed, inject, signal, untracked } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[readonly]',
  standalone: true,
  exportAs: 'readonly',
  host: {
    '[attr.readonly]': 'readonly || null',
  },
})
export class ReadonlyDirective {
  readonly #parent = inject(ReadonlyDirective, { optional: true, skipSelf: true });
  protected readonly _readonly = signal<boolean | undefined>(undefined);
  readonly #readonlyFinal = computed(() => this._readonly() ?? this.#parent?._readonly() ?? false);

  @Input()
  public get readonly(): boolean {
    return untracked(this.#readonlyFinal);
  }

  public set readonly(value: BooleanInput) {
    this._readonly.set(value === undefined ? undefined : coerceBooleanProperty(value));
  }

  @Output()
  public readonly readonlyChange = toObservable(this.#readonlyFinal);
}
