import { Directive, effect } from '@angular/core';
import { AbstractControl, FormControl, TouchedChangeEvent, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AbstractFormControlBase } from './AbstractFormControlBase';

@Directive()
export abstract class AbstractFormControl<TValue = unknown> extends AbstractFormControlBase<TValue, FormControl<TValue>> {
  constructor() {
    super();
    let touchSubs: Subscription | undefined;
    effect((onCleanup) => {
      const control = this.control();
      touchSubs?.unsubscribe();
      touchSubs = control?.events.subscribe((e) => {
        if (e instanceof TouchedChangeEvent && e.touched) {
          this.markAsTouched();
        }
      });
      onCleanup(() => {
        touchSubs?.unsubscribe();
      });
    });
  }

  validate(_: AbstractControl<TValue>): ValidationErrors | null {
    let err: ValidationErrors | null = null;
    const control = this.control();
    if (!control) {
      return null;
    }
    if (control.errors) {
      err = { ...control.errors };
    }
    return err;
  }
}
