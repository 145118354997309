import { JsonPipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { Address, AddressService } from 'forms-data';

export enum AddressLayout {
  Inline = 'inline',
  Block = 'block',
  Labeled = 'labeled',
}

export type AddressLayouts = `${AddressLayout}`;

@Component({
  selector: 'ideal-address',
  standalone: true,
  imports: [TitleCasePipe, UpperCasePipe, JsonPipe],
  templateUrl: './address.component.html',
  styleUrls: ['./address.layout.scss', './address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.layout]': 'layout()',
  },
})
export class AddressComponent {
  private readonly _addressService = inject(AddressService);

  protected readonly AddressLayout = AddressLayout;
  public readonly address = input<Address>();
  public readonly layout = input<AddressLayout, AddressLayout | AddressLayouts>(AddressLayout.Inline, {
    transform: (value) => value as AddressLayout,
  });

  protected countryName = computed(() => (this.address() ? this._addressService.getCountryName(this.address()!.country) : ''));

  protected regionName = computed(() =>
    this.address() ? this._addressService.getRegionName(this.address()!.country, this.address()!.region) : '',
  );

  protected regionLabel = computed(() => (this.address() ? this._addressService.getRegionLabel(this.address()!.country) : 'Province'));

  protected postalCodeLabel = computed(() =>
    this.address() ? this._addressService.getPostalCodeLabel(this.address()!.country) : 'Postal Code',
  );
}
