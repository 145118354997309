@let form = formGroup();

<form [formGroup]="form" novalidate (ngSubmit)="onSubmit($event)" (reset)="onReset($event)">
  <ng-content />
  <div actions-bar>
    <div start>
      <ng-content select="[reset-button]">
        <button mat-button type="reset" [disabled]="formDisabled() || !form.dirty">{{ resetLabel() }}</button>
      </ng-content>
    </div>
    <div center></div>
    <div end>
      <ng-content select="submitButton">
        <button mat-flat-button color="primary" type="submit" (click)="onSubmitClick($event)" [disabled]="formDisabled()">
          {{ submitLabel() }}
        </button>
      </ng-content>
    </div>
  </div>
</form>
