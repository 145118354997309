import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DecimalPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { provideValueAccessor } from '../../old/base';
import { TextInputComponent } from '../text-input';

@Component({
  selector: 'ideal-textarea-input',
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, ReactiveFormsModule, DecimalPipe, CdkTextareaAutosize, JsonPipe],
  templateUrl: './textarea-input.component.html',
  styleUrls: ['../base/form-field-base.scss', './textarea-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(TextareaInputComponent, false)],
})
export class TextareaInputComponent extends TextInputComponent {
  public readonly textAreaSize = input<'auto' | number | null>('auto');
  public readonly textAreaMinRows = input(1);
  public readonly textAreaMaxRows = input(15);

  protected readonly autosize = computed(() => this.textAreaSize() === 'auto');
  protected readonly resizable = computed(() => (this.textAreaSize() === null ? 'vertical' : 'none'));

  protected readonly textAreaMinHeight = computed(() => {
    const textAreaSize = this.textAreaSize();
    const textAreaMinRows = this.textAreaMinRows();
    if (textAreaSize === 'auto') {
      return undefined;
    } else if (textAreaSize === null) {
      return textAreaMinRows;
    } else {
      return textAreaSize;
    }
  });

  protected readonly textAreaMaxHeight = computed(() => {
    const textAreaSize = this.textAreaSize();
    const textAreaMaxRows = this.textAreaMaxRows();
    if (textAreaSize === 'auto') {
      return undefined;
    } else if (textAreaSize === null) {
      return textAreaMaxRows;
    } else {
      return textAreaSize;
    }
  });
}
