@if (control(); as control) {
  <form novalidate [formGroup]="control">
    <mat-form-field class="name">
      <mat-label i18n="@@address.name.label">Name</mat-label>
      <input matInput formControlName="name" i18n-placeholder="@@address.name.placeholder" placeholder="Name" />
      @if (control.controls?.name?.hasError("maxlength")) {
        <mat-error
          ><span i18n="@@address.name.label">Name</span>&nbsp;<span i18n="@@forms.errors.is-too-long"
            >is too long. {{ control.controls!.name!.errors!["maxlength"].actualLength }}/{{
              control.controls!.name!.errors!["maxlength"].requiredLength
            }}</span
          ></mat-error
        >
      }
      @if (control.controls?.name?.hasError("missing")) {
        <mat-error
          ><span i18n="@@address.name.label">Name</span>&nbsp;<span i18n="@@forms.errors.field-is-missing">is missing.</span></mat-error
        >
      }
      @if (control.controls?.name?.hasError("required")) {
        <mat-error
          ><span i18n="@@address.name.label">Name</span>&nbsp;<span i18n="@@forms.errors.field-is-required">is required.</span></mat-error
        >
      }
    </mat-form-field>

    <mat-form-field class="company">
      <mat-label i18n="@@address.company.label">Company</mat-label>
      <input matInput formControlName="company" i18n-placeholder="@@address.company.placeholder" placeholder="Company" />
      @if (control.controls?.company?.hasError("maxlength")) {
        <mat-error
          ><span i18n="@@address.company.label">Company</span>&nbsp;<span i18n="@@forms.errors.is-too-long"
            >is too long. {{ control.controls!.company!.errors!["maxlength"].actualLength }}/{{
              control.controls!.company!.errors!["maxlength"].requiredLength
            }}</span
          ></mat-error
        >
      }
      @if (control.controls?.company?.hasError("missing")) {
        <mat-error
          ><span i18n="@@address.company.label">Company</span>&nbsp;<span i18n="@@forms.errors.field-is-missing"
            >is missing.</span
          ></mat-error
        >
      }
      @if (control.controls?.company?.hasError("required")) {
        <mat-error
          ><span i18n="@@address.company.label">Company</span>&nbsp;<span i18n="@@forms.errors.field-is-required"
            >is required.</span
          ></mat-error
        >
      }
    </mat-form-field>

    <mat-form-field class="line1">
      <mat-label i18n="@@address.line1.label">Line 1</mat-label>
      <input matInput formControlName="line1" i18n-placeholder="@@address.line1.placeholder" placeholder="Enter your Street" />
      @if (control.controls?.line1?.hasError("maxlength")) {
        <mat-error
          ><span i18n="@@address.line1.label">Line 1</span>&nbsp;<span i18n="@@forms.errors.is-too-long"
            >is too long. {{ control.controls!.line1!.errors!["maxlength"].actualLength }}/{{
              control.controls!.line1!.errors!["maxlength"].requiredLength
            }}</span
          ></mat-error
        >
      }
      @if (control.controls?.line1?.hasError("missing")) {
        <mat-error
          ><span i18n="@@address.line1.label">Line 1</span>&nbsp;<span i18n="@@forms.errors.field-is-missing">is missing.</span></mat-error
        >
      }
      @if (control.controls?.line1?.hasError("required")) {
        <mat-error
          ><span i18n="@@address.line1.label">Line 1</span>&nbsp;<span i18n="@@forms.errors.field-is-required"
            >is required.</span
          ></mat-error
        >
      }
    </mat-form-field>

    <mat-form-field class="line2">
      <mat-label i18n="@@address.line2.label">Line 2</mat-label>
      <input matInput formControlName="line2" i18n-placeholder="@@address.line2.placeholder" placeholder="Apt #, Floor, etc." />
      @if (control.controls?.line2?.hasError("maxlength")) {
        <mat-error
          ><span i18n="@@address.line2.label">Line 2</span>&nbsp;<span i18n="@@forms.errors.is-too-long"
            >is too long. {{ control.controls!.line2!.errors!["maxlength"].actualLength }}/{{
              control.controls!.line2!.errors!["maxlength"].requiredLength
            }}</span
          ></mat-error
        >
      }
    </mat-form-field>

    <mat-form-field class="locality">
      <mat-label i18n="@@address.locality.label">City</mat-label>
      <input matInput formControlName="locality" i18n-placeholder="@@address.locality.placeholder" placeholder="City" />
      @if (control.controls?.locality?.hasError("maxlength")) {
        <mat-error
          ><span i18n="@@address.locality.label">City</span>&nbsp;<span i18n="@@forms.errors.is-too-long"
            >is too long. {{ control.controls!.locality!.errors!["maxlength"].actualLength }}/{{
              control.controls!.locality!.errors!["maxlength"].requiredLength
            }}</span
          ></mat-error
        >
      }
      @if (control.controls?.locality?.hasError("missing")) {
        <mat-error
          ><span i18n="@@address.locality.label">City</span>&nbsp;<span i18n="@@forms.errors.field-is-missing">is missing.</span></mat-error
        >
      }
      @if (control.controls?.locality?.hasError("required")) {
        <mat-error
          ><span i18n="@@address.locality.label">City</span>&nbsp;<span i18n="@@forms.errors.field-is-required"
            >is required.</span
          ></mat-error
        >
      }
    </mat-form-field>

    <mat-form-field class="postalCode">
      <mat-label>{{ postalCodeLabel() }}</mat-label>
      <input matInput formControlName="postalCode" [placeholder]="postalCodePlaceholder()" />

      @if (control.controls?.postalCode?.hasError("missing")) {
        <mat-error i18n="@@address.postalCode.error.missing">{{ postalCodeLabel() }} is missing.</mat-error>
      }
      @if (control.controls?.postalCode?.hasError("required")) {
        <mat-error i18n="@@address.postalCode.error.required">{{ postalCodeLabel() }} is required.</mat-error>
      }
      @if (control.controls?.postalCode?.hasError("format")) {
        <mat-error>{{ control.controls!.postalCode!.errors!["format"] }}</mat-error>
      }
    </mat-form-field>

    <mat-form-field class="country">
      <mat-label i18n="@@address.country.label">Country</mat-label>
      <ideal-country-select
        #country
        formControlName="country"
        i18n-placeholder="@@address.country.placeholder"
        placeholder="Select Country"
      />
      @if (control.controls?.country?.hasError("missing")) {
        <mat-error i18n="@@address.country.error.missing">Country is missing.</mat-error>
      }
      @if (control.controls?.country?.hasError("required")) {
        <mat-error i18n="@@address.country.error.required">Country is required.</mat-error>
      }
    </mat-form-field>

    <mat-form-field class="region">
      <mat-label>{{ regionLabel() }}</mat-label>
      <ideal-region-select formControlName="region" [placeholder]="regionPlaceholder()" [country]="country.value" />
      @if (control.controls?.region?.hasError("missing")) {
        <mat-error i18n="@@address.region.error.missing">{{ regionLabel() }} is missing.</mat-error>
      }
      @if (control.controls?.region?.hasError("required")) {
        <mat-error i18n="@@address.region.error.required">{{ regionLabel() }} is required.</mat-error>
      }
    </mat-form-field>

    @if (coordinatesEnabled()) {
      @if (coordinateFieldsHidden()) {
        <input type="hidden" formControlName="longitude" />
        <input type="hidden" formControlName="latitude" />
      } @else {
        <mat-form-field class="longitude">
          <mat-label i18n="@@address.longitude.label">Longitude</mat-label>
          <input
            matInput
            type="number"
            formControlName="longitude"
            placeholder="Enter Longitude"
            i18n-placeholder="@@address.longitude.placeholder"
            xrequiredx="areCoordinatesRequired()"
          />
          @if (control.controls?.longitude?.hasError("missing")) {
            <mat-error i18n="@@address.longitude.error.missing">Longitude is missing.</mat-error>
          }
          @if (control.controls?.longitude?.hasError("required")) {
            <mat-error i18n="@@address.longitude.error.required">Longitude is required.</mat-error>
          }
        </mat-form-field>

        <mat-form-field class="latitude">
          <mat-label i18n="@@address.latitude.label">Latitude</mat-label>
          <input
            matInput
            type="number"
            formControlName="latitude"
            placeholder="Enter Latitude"
            i18n-placeholder="@@address.latitude.placeholder"
          />
          @if (control.controls?.latitude?.hasError("missing")) {
            <mat-error i18n="@@address.latitude.error.missing">Latitude is missing.</mat-error>
          }
          @if (control.controls?.latitude?.hasError("required")) {
            <mat-error i18n="@@address.latitude.error.required">Latitude is required.</mat-error>
          }
        </mat-form-field>
      }
    }
  </form>

  <!-- <pre>control.valid: {{ control.valid | json }}</pre>
  <pre>control.value: {{ control.value | json }}</pre>
  <pre>control.errors: {{ control.errors | json }}</pre>

  <pre>ngControl?.control?.valid: {{ ngControl?.control?.valid | json }}</pre>
  <pre>ngControl?.control?.value: {{ ngControl?.control?.value | json }}</pre>
  <pre>ngControl?.control?.errors: {{ ngControl?.control?.errors | json }}</pre> -->
}
