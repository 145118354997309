@if (control(); as control) {
  <ideal-address-search (addressSelected)="onAddressSelected($event)" [disabled]="disabled()"></ideal-address-search>
  <ideal-address-form
    [formControl]="control"
    [maxLengthLine1]="maxLengthLine1()"
    [maxLengthLine2]="maxLengthLine2()"
    [maxLengthLocality]="maxLengthLocality()"
    [coordinates]="coordinatesEnabled()"
    [coordinatesRequired]="coordinatesRequired()"
    [coordinatesHidden]="coordinatesHidden()"
  ></ideal-address-form>
}
