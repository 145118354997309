import { DecimalPipe, JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  LOCALE_ID,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ControlEvent, ReactiveFormsModule, StatusChangeEvent, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  CharactersAllowedResult,
  checkValidCharacters,
  DecimalFormControl,
  DefaultNumberFormControlOptions,
  IntegerFormControl,
  NumberFormControl,
  NumberFormControlOptions,
  NumberFormMultiplesOfStrategy,
  NumberFormRangeStrategy,
  TextFormControl,
} from 'forms-data';
import { distinctUntilChanged, fromEvent, map, merge } from 'rxjs';
import { provideValueAccessor } from '../../old/base';
import { AbstractFormField } from '../base';

@Component({
  selector: 'ideal-number-input',
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, ReactiveFormsModule, DecimalPipe, [JsonPipe]],
  templateUrl: './number-input.component.html',
  styleUrls: ['../base/form-field-base.scss', './number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(NumberInputComponent, false), DecimalPipe],
})
export class NumberInputComponent extends AbstractFormField<number, NumberFormControl> {
  private readonly _decimalPipe = inject(DecimalPipe);

  private _keyup$ = fromEvent<KeyboardEvent>(document, 'keyup');
  private _keydown$ = fromEvent<KeyboardEvent>(document, 'keydown');
  private _blur$ = fromEvent<KeyboardEvent>(window, 'blur');
  private _focus$ = fromEvent<KeyboardEvent>(window, 'focus');

  private _locale = inject(LOCALE_ID);
  private _decimalSeparator =
    Intl.NumberFormat(this._locale)
      .formatToParts(1.1)
      .find((part) => part.type === 'decimal')?.value ?? '.';
  private _groupSeparator =
    Intl.NumberFormat(this._locale)
      .formatToParts(1000)
      .find((part) => part.type === 'group')?.value ?? ',';

  public readonly label = input<string>('');
  public readonly placeholder = input<string>('');
  public readonly field = viewChild.required<ElementRef<HTMLInputElement>>('field');

  protected readonly internalControl = signal<TextFormControl>(new TextFormControl(''));
  protected override createDefaultControl(): NumberFormControl {
    return new DecimalFormControl(0);
  }

  protected readonly modifiers = toSignal(
    merge(this._keydown$, this._keyup$, this._blur$, this._focus$).pipe(
      map((evt) => ({
        shiftKey: evt.shiftKey ?? false,
        ctrlKey: evt.ctrlKey ?? false,
      })),
      distinctUntilChanged((a, b) => a.shiftKey === b.shiftKey && a.ctrlKey === b.ctrlKey),
    ),
    { initialValue: { shiftKey: false, ctrlKey: false } },
  );

  protected readonly options = computed(
    (): Required<NumberFormControlOptions> => this.control().options ?? { ...DefaultNumberFormControlOptions },
  );

  protected readonly step = computed(() => {
    const options = this.options();
    const modifiers = this.modifiers();
    if (modifiers.ctrlKey) return options.stepCtrl ?? options.step ?? 1;
    if (modifiers.shiftKey) return options.stepShift ?? options.step ?? 1;
    return options.step ?? 1;
  });

  protected readonly snap = computed(() => {
    const options = this.options();
    return options.multiplesOf;
  });

  protected readonly format = computed(() => {
    const options = this.options();
    const minIntegerDigits = options.formatMinIntegerDigits ?? 1;
    const minFractionDigits = options.formatMinFractionDigits ?? 0;
    const maxFractionDigits = options.formatMaxFractionDigits ?? 10;

    return `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}`;
  });

  protected readonly allowDecimals = computed(() => {
    const control = this.control();
    const options = this.options();
    return (
      !(control instanceof IntegerFormControl) && (options.multiplesOf === null || options.multiplesOf !== Math.floor(options.multiplesOf))
    );
  });

  public override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    const control = this.control();
    const options = this.options();

    const decimalSymbol = this._decimalSeparator === '.' ? '\\.' : this._decimalSeparator;

    const groupSymbol = this._groupSeparator === '.' ? '\\.' : this._groupSeparator;
    const negativeSymbol = options.min !== null && options.min >= 0 ? '' : '\\-';
    const pattern = `[${negativeSymbol}0-9${groupSymbol}${decimalSymbol}]`;

    const iValue = '' + ((control.value as number | null) ?? (control.defaultValue as number | null) ?? '');

    const fValue = this._decimalPipe.transform(iValue, this.format()) ?? '';

    const iCtrl = new TextFormControl(fValue, {
      nonNullable: true,
      trim: true,
      charactersAllowed: new RegExp(pattern),
      characterValidationStrategy: 'restrict',
      additionalValidators: [
        () => {
          return this.control().errors;
        },
      ],
    });

    this.internalControl.set(iCtrl);
  }

  // override writeValue(value: any): void {
  //   super.writeValue(value);
  //   this.internalControl().markAsUntouched();
  //   this.internalControl().markAsPristine();
  // }

  protected onFocus(evt: FocusEvent): void {}

  protected onBlur(evt: FocusEvent): void {
    const control = this.control();
    const field = this.field().nativeElement;
    const cValue = control.value;
    const newValue = this.applyRestrictToValue(this.applySnapToValue(cValue, 'input'), 'input');
    if (cValue !== newValue) {
      if (this.field().nativeElement.value.trim() !== '') {
        control.setValue(newValue);
      }
    }
    field.value = this.modelToViewFormat(control.value);
  }

  protected get startHint(): string {
    const control = this.control();
    const options = this.options();
    const min = options.min;
    const max = options.max;
    const value = control.value ?? 0;

    const minStr = min !== null ? this._decimalPipe.transform(min, this.format()) : '';
    const maxStr = max !== null ? this._decimalPipe.transform(max, this.format()) : '';

    let hint = '';
    if (min !== null && max !== null) {
      hint = value < min ? `Min: ${minStr}` : `Max: ${maxStr}`;
    } else if (min !== null) {
      hint = value <= min ? `Min: ${minStr}` : '';
    } else if (max !== null) {
      hint = `Max: ${maxStr}`;
    }

    if (
      options.multiplesOf &&
      options.multiplesOf !== 1 &&
      options.multiplesOfStrategy !== NumberFormMultiplesOfStrategy.RestrictOnIncrementOrIgnore
    ) {
      hint += (hint ? ', ' : '') + 'Multiples of: ' + this._decimalPipe.transform(options.multiplesOf, this.format());
    }
    return hint;
  }

  protected get endHint(): string {
    const step = this.step();
    return '±' + this._decimalPipe.transform(step, this.format());
  }

  protected onMousewheel(evt: WheelEvent): void {
    evt.deltaY >= 0 ? this.decrement() : this.increment();
    evt.preventDefault();
  }

  protected onKeyDown(evt: KeyboardEvent): void {
    if (evt.key === 'ArrowUp') {
      this.increment();
      evt.preventDefault();
    } else if (evt.key === 'ArrowDown') {
      this.decrement();
      evt.preventDefault();
    }
  }

  private increment() {
    const control = this.control();
    this.set(control.value + this.step());
  }

  private decrement() {
    const control = this.control();
    this.set(control.value - this.step());
  }

  private applyRestrictToValue(value: number, type?: 'input' | 'inc'): number {
    const options = this.options();
    if (
      !type ||
      (type === 'input' && options.rangeStrategy === NumberFormRangeStrategy.Restrict) ||
      (type === 'inc' &&
        (options.rangeStrategy === NumberFormRangeStrategy.Restrict ||
          options.rangeStrategy === NumberFormRangeStrategy.RestrictOnIncrement))
    ) {
      return Math.max(Math.min(value, options.max ?? Infinity), options.min ?? -Infinity);
    }
    return value;
  }

  private applySnapToValue(value: number, type?: 'input' | 'inc'): number {
    const options = this.options();
    if (
      !type ||
      (type === 'input' && options.multiplesOfStrategy === 'restrict') ||
      (type === 'inc' &&
        (options.multiplesOfStrategy === NumberFormMultiplesOfStrategy.Restrict ||
          options.multiplesOfStrategy === NumberFormMultiplesOfStrategy.RestrictOnIncrementOrIgnore ||
          options.multiplesOfStrategy === NumberFormMultiplesOfStrategy.RestrictOnIncrementOrError))
    ) {
      const snap = this.snap();
      if (snap !== null) {
        return Math.round(value / snap) * snap;
      }
    }
    return value;
  }

  protected set(val: number) {
    const control = this.control();
    const oldValue = control.value;
    let newValue = val;
    newValue = this.applySnapToValue(newValue, 'inc');
    newValue = this.applyRestrictToValue(newValue, 'inc');
    if (oldValue !== newValue) {
      const v = this.viewToModelFormat(this.modelToViewFormat(newValue));
      this.valueInput.set(v);
    }
  }

  protected onKeypress(evt: KeyboardEvent): void {
    const opts = this.internalControl().options!;

    if (opts.characterValidationStrategy !== 'restrict') return;

    const field = this.field().nativeElement;
    const value = field.value;

    if (evt.key === '-' && (field.selectionStart !== 0 || value.includes('-'))) {
      evt.preventDefault();
    } else if (evt.key === this._decimalSeparator && !this.allowDecimals()) {
      evt.preventDefault();
    } else if (evt.key.length === 1) {
      const check = checkValidCharacters(evt.key, opts.charactersAllowed, opts.characterDenied);
      if (check !== CharactersAllowedResult.Valid) {
        evt.preventDefault();
      }
    }
  }

  protected onInput(evt: Event): void {
    const control = this.control();
    const iControl = this.internalControl();
    if (iControl.value === '-') return;

    let iValue = iControl.value === '' ? null : this.viewToModelFormat(iControl.value);
    if (evt instanceof InputEvent) {
      if (evt.inputType === 'insertFromPaste') {
        const opts = this.internalControl().options!;
        const field = this.field().nativeElement;

        let newValue = field.value;
        for (let i = 0; i < field.value.length; i++) {
          const char = field.value[i];
          const check = checkValidCharacters(char, opts.charactersAllowed, opts.characterDenied);
          if (check !== CharactersAllowedResult.Valid) {
            newValue = newValue.replaceAll(char, '');
          }
        }

        if (!this.allowDecimals()) {
          newValue = newValue.split(this._decimalSeparator)[0];
        }
        iValue = this.viewToModelFormat(newValue);
      }
    }

    if (control.value !== iValue) {
      if (control.options?.nonNullable === true && iValue === null) {
        control.setValue(0);
      } else {
        control.setValue(iValue as number);
      }
    }
  }

  constructor() {
    super();
    let firstChange = true;
    effect(() => {
      const value = this.valueInput();
      if (!firstChange) {
        const iCtrl = untracked(this.internalControl);
        const iVal = this.viewToModelFormat(iCtrl.value);
        if (value !== iVal) {
          // TODO: fix as any
          iCtrl.setValue(this.modelToViewFormat(value as any));
        }
      }
      firstChange = false;
    });
  }

  protected override onControlEvent(controlEvent: ControlEvent<number>) {
    super.onControlEvent(controlEvent);
    if (controlEvent instanceof StatusChangeEvent) {
      const iCtrl = this.internalControl();
      const options = this.options();
      if (options.required && !iCtrl.hasValidator(Validators.required)) {
        iCtrl.addValidators(Validators.required);
      } else if (!options.required && iCtrl.hasValidator(Validators.required)) {
        iCtrl.removeValidators(Validators.required);
      }
      iCtrl.updateValueAndValidity();
    }
  }

  protected viewToModelFormat(value: string): number {
    value ||= '0';
    const stripped = value.replaceAll(this._groupSeparator, '')?.replaceAll(this._decimalSeparator, '.');
    return Number.parseFloat(stripped);
  }

  protected modelToViewFormat(value: number | null): string {
    return this._decimalPipe.transform(value, this.format()) ?? '';
  }

  protected onAutoFill(event: any) {
    setTimeout(() => {}, 1000);
  }
}
