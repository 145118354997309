import { Provider, Type } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FORM_CONTROL } from './FORM_CONTROL';

export function provideValueAccessor(type: Type<any>, isValidator: boolean = true): Provider {
  const providers = [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: type,
      multi: true,
    },
    {
      provide: FORM_CONTROL,
      useExisting: type,
    },
  ];
  if (isValidator) {
    providers.push({
      provide: NG_VALIDATORS,
      useExisting: type,
      multi: true,
    });
  }
  return providers;
}
