import { DecimalPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DefaultEmailFormControlOptions, EmailFormControlOptions, PasswordFormControl, TextFormControlOptions } from 'forms-data';
import { provideValueAccessor } from '../../old/base';
import { TextInputComponent } from '../text-input';

@Component({
  selector: 'ideal-email-input',
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, ReactiveFormsModule, DecimalPipe, MatIconModule, MatButtonModule, JsonPipe],
  templateUrl: './email-input.component.html',
  styleUrls: ['../base/form-field-base.scss', './email-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(EmailInputComponent, false)],
})
export class EmailInputComponent extends TextInputComponent<PasswordFormControl> {
  protected override readonly options = computed(
    (): Required<EmailFormControlOptions & TextFormControlOptions> =>
      (this.control().options ?? { ...DefaultEmailFormControlOptions }) as Required<EmailFormControlOptions & TextFormControlOptions>,
  );

  constructor() {
    super();
  }
}
