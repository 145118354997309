import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Contact } from 'forms-data';
import { AddressComponent, AddressLayout as ContactLayout, AddressLayouts as ContactLayouts } from '../../address';
import { LowerCasePipe, TitleCasePipe } from '@angular/common';

export { AddressLayout as ContactLayout, AddressLayouts as ContactLayouts } from '../../address';

@Component({
  selector: 'ideal-contact',
  standalone: true,
  imports: [AddressComponent, TitleCasePipe, LowerCasePipe],
  templateUrl: './contact.component.html',
  styleUrls: ['../../address/address/address.layout.scss', './contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactComponent {
  protected readonly ContactLayout = ContactLayout;
  public readonly contact = input<Contact>();
  public readonly layout = input<ContactLayout, ContactLayout | ContactLayouts>(ContactLayout.Inline, {
    transform: (value) => value as ContactLayout,
  });
}
