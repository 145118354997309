import { Directive, effect } from '@angular/core';
import { AbstractControl, FormGroup, TouchedChangeEvent, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AbstractControlWrap } from './AbstractControlWrap';
import { AbstractFormControlBase } from './AbstractFormControlBase';

@Directive()
export abstract class AbstractFormGroupControl<TValue = unknown> extends AbstractFormControlBase<
  TValue,
  FormGroup<AbstractControlWrap<TValue>>
> {
  validate(_: AbstractControl<TValue>): ValidationErrors | null {
    let err: ValidationErrors | null = null;
    const control = this.control();
    if (!control) {
      return null;
    }
    if (control.errors) {
      err = { ...control.errors };
    }

    for (const key in control.controls) {
      if (control.controls[key]?.errors) {
        err ??= {};
        err['field'] ??= {};
        err['field'][key] = control.controls[key]?.errors;
      }
    }
    return err;
  }

  constructor() {
    super();
    let touchSubs = new Subscription();
    effect((onCleanup) => {
      const control = this.control();
      touchSubs.unsubscribe();
      touchSubs = new Subscription();
      if (control) {
        for (const key in control.controls) {
          const sub = control.controls[key]?.events.subscribe((e) => {
            if (e instanceof TouchedChangeEvent && e.touched) {
              this.markAsTouched();
            }
          });
          touchSubs.add(sub);
        }
      }
      onCleanup(() => {
        touchSubs.unsubscribe();
      });
    });
  }
}
