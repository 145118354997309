<mat-form-field>
  <mat-label>Search Address</mat-label>
  <input
    #input
    type="text"
    placeholder="Start typing to find the address"
    matInput
    [matAutocomplete]="auto"
    (input)="searchUpdated(input.value)"
    [disabled]="disabled()"
  />
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" (optionSelected)="onOptionSelected($event)">
  @for (suggestion of suggestions(); track suggestion.placeId) {
    <mat-option [value]="$index">{{ suggestion.text.text }}</mat-option>
  }
</mat-autocomplete>
