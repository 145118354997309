@if (address(); as address) {
  @if (address.name) {
    <label class="name" i18n="@@address.name">Name</label>
    <div class="name">{{ address.name | titlecase }}</div>
  }

  @if (address.company) {
    <label class="name" i18n="@@address.came">Company</label>
    <div class="name">{{ address.company | titlecase }}</div>
  }

  <label class="line1" i18n="@@address.line1">Line 1</label>
  <div class="line1">{{ address.line1 | titlecase }}</div>

  @if (address.line2) {
    <label class="line2" i18n="@@address.line2">Line 2</label>
    <div class="line2">{{ address.line2 | titlecase }}</div>
  }

  <div class="line3">
    <label class="locality" i18n="@@address.locality">City</label>
    <span class="locality">{{ address.locality | titlecase }}</span>
    <label class="region">{{ regionLabel() }}</label>
    <span class="region">{{ regionName() }}</span>
    <label class="postalCode">{{ postalCodeLabel() }}</label>
    <span class="postalCode">{{ address.postalCode | uppercase }}</span>
  </div>
  <label class="country" i18n="@@address.country">Country</label>
  <div class="country">{{ countryName() }}</div>
}
