import { Directive, effect, inject, untracked } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { FORM_CONTROL } from './FORM_CONTROL';
import { nextUID } from './nextUID';
import { provideMatFormFieldControl } from './provideMatFormFieldControl';

@Directive({
  selector: '[mat-form-control]',
  standalone: true,
  providers: [provideMatFormFieldControl(MatFormFieldAdaptorDirective)],
  host: {
    '[id]': 'id',
  },
})
export class MatFormFieldAdaptorDirective implements MatFormFieldControl<any> {
  #controlComponent = inject(FORM_CONTROL);

  readonly #stateChanges$$ = new Subject<void>();
  readonly stateChanges = this.#stateChanges$$.asObservable();

  get controlType() {
    return this.#controlComponent.controlType ?? 'form-control';
  }

  get id() {
    return this.#controlComponent.id ?? nextUID(this.controlType);
  }

  get placeholder() {
    return (this.#controlComponent.placeholder ? untracked(this.#controlComponent.placeholder) : '') ?? '';
  }

  get ngControl() {
    return this.#controlComponent.ngControl;
  }

  get empty() {
    return untracked(this.#controlComponent.empty) ?? true;
  }

  get focused() {
    return untracked(this.#controlComponent.focused) ?? false;
  }

  get shouldLabelFloat() {
    return (this.#controlComponent.shouldLabelFloat ? untracked(this.#controlComponent.shouldLabelFloat) : true) ?? true;
  }

  get required() {
    return untracked(this.#controlComponent.required) ?? false;
  }

  get disabled() {
    return untracked(this.#controlComponent.disabled) ?? false;
  }

  get errorState() {
    return this.#controlComponent.ngControl?.invalid ?? false;
  }

  get value() {
    return untracked(this.#controlComponent.control)?.value;
  }

  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}

  constructor() {
    effect(() => {
      this.#controlComponent.placeholder?.();
      this.#controlComponent.required?.();
      this.#controlComponent.disabled?.();
      this.#controlComponent.control?.();
      this.#controlComponent.empty?.();
      this.#controlComponent.statusChanges();
      this.#controlComponent.valueChanges();

      this.#stateChanges$$.next();
    });
  }
}
