import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, booleanAttribute, computed, effect, inject, input } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { booleanOrUndefinedAttribute } from 'common-util';
import { Address, AddressService, AddressValidators } from 'forms-data';
import { EMPTY, mergeWith, switchMap } from 'rxjs';
import { AbstractControlWrap, AbstractFormGroupControl, MatFormFieldAdaptorDirective } from '../../base';
import { provideValueAccessor } from '../../base/provideValueAccessor';
import { CountrySelectComponent } from '../country-select';
import { RegionSelectComponent } from '../region-select';

@Component({
  selector: 'ideal-address-form',
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    MatLabel,
    CountrySelectComponent,
    MatError,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    RegionSelectComponent,
    JsonPipe,
  ],
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(AddressFormComponent)],
  hostDirectives: [MatFormFieldAdaptorDirective],
})
export class AddressFormComponent extends AbstractFormGroupControl<Address | null> {
  readonly #addressService = inject(AddressService);
  readonly #country = toSignal(toObservable(this.control).pipe(switchMap((control) => control?.controls?.country?.valueChanges ?? EMPTY)));

  public controlType = 'address';

  readonly maxLengthName = input(30);
  readonly maxLengthCompany = input(30);
  readonly maxLengthLine1 = input(30);
  readonly maxLengthLine2 = input(30);
  readonly maxLengthLocality = input(20);

  readonly coordinatesEnabled = input(false, { alias: 'coordinates', transform: booleanAttribute });
  readonly coordinatesRequired = input(undefined, { transform: booleanOrUndefinedAttribute });
  readonly coordinatesHidden = input(undefined, { transform: booleanOrUndefinedAttribute });

  protected readonly nameField = new FormControl<string>('', { nonNullable: true });
  protected readonly companyField = new FormControl<string>('', { nonNullable: true });
  protected readonly line1Field = new FormControl<string>('', { nonNullable: true });
  protected readonly line2Field = new FormControl<string>('', { nonNullable: true });
  protected readonly postalCodeField = new FormControl<string>('', { nonNullable: true, validators: [AddressValidators.postalCode()] });
  protected readonly localityField = new FormControl<string>('', { nonNullable: true });
  protected readonly regionField = new FormControl<string>('', { nonNullable: true, validators: [AddressValidators.region()] });
  protected readonly countryField = new FormControl<string>('', { nonNullable: true });
  protected readonly longitudeField = new FormControl<number>(0, { nonNullable: true, validators: [AddressValidators.longitude] });
  protected readonly latitudeField = new FormControl<number>(0, { nonNullable: true, validators: [AddressValidators.latitude] });

  protected readonly fieldEvents$ = this.line1Field.events.pipe(
    mergeWith(
      this.nameField.events,
      this.companyField.events,
      this.line2Field.events,
      this.localityField.events,
      this.regionField.events,
      this.postalCodeField.events,
      this.countryField.events,
      this.longitudeField.events,
      this.latitudeField.events,
    ),
    takeUntilDestroyed(),
  );

  constructor() {
    super();

    this.setControl(
      new FormGroup<AbstractControlWrap<Address | null>>(
        {
          country: this.countryField,
          name: this.nameField,
          company: this.companyField,
          line1: this.line1Field,
          line2: this.line2Field,
          locality: this.localityField,
          region: this.regionField,
          postalCode: this.postalCodeField,
          longitude: this.longitudeField,
          latitude: this.latitudeField,
        },
        {
          validators: [
            (c) => {
              if (this.required()) {
                return null;
              }
              let err = AddressValidators.address(c);
              if (err) {
                const missing = err['missing'];
                if (missing) {
                  if (missing.name) {
                    this.nameField.setErrors({ missing: true });
                  }
                  if (missing.line1) {
                    this.line1Field.setErrors({ missing: true });
                  }
                  if (missing.locality) {
                    this.localityField.setErrors({ missing: true });
                  }
                  if (missing.postalCode) {
                    this.postalCodeField.setErrors({ missing: true });
                  }
                  if (missing.region) {
                    this.regionField.setErrors({ missing: true });
                  }
                  if (missing.country) {
                    this.countryField.setErrors({ missing: true });
                  }
                }
              }

              if (this.coordinatesRequired() && this.longitudeField.value === null) {
                this.longitudeField.setErrors({ missing: true });
                err ??= {};
                err['missing'] ??= {};
                err['missing']['longitude'] = true;
              }
              if (this.coordinatesRequired() && this.latitudeField.value === null) {
                this.latitudeField.setErrors({ missing: true });
                err ??= {};
                err['missing'] ??= {};
                err['missing']['latitude'] = true;
              }

              return err;
            },
          ],
        },
      ),
    );

    effect(() => {
      const maxLengthName = this.maxLengthName();
      const maxLengthCompany = this.maxLengthCompany();
      const maxLengthLine1 = this.maxLengthLine1();
      const maxLengthLine2 = this.maxLengthLine2();
      const maxLengthLocality = this.maxLengthLocality();

      let maxLengthLine1Validator: ValidatorFn | undefined;
      let maxLengthNameValidator: ValidatorFn | undefined;
      let maxLengthCompanyValidator: ValidatorFn | undefined;
      let maxLengthLine2Validator: ValidatorFn | undefined;
      let maxLengthLocalityValidator: ValidatorFn | undefined;

      if (maxLengthLine1Validator) {
        this.line1Field.removeValidators(maxLengthLine1Validator);
      }

      if (maxLengthLine1) {
        maxLengthLine1Validator = Validators.maxLength(maxLengthLine1);
        this.line1Field.addValidators(maxLengthLine1Validator);
      }
      this.line1Field.updateValueAndValidity({ onlySelf: true });

      if (maxLengthName) {
        maxLengthNameValidator = Validators.maxLength(maxLengthName);
        this.nameField.addValidators(maxLengthNameValidator);
      }
      this.nameField.updateValueAndValidity({ onlySelf: true });

      if (maxLengthCompanyValidator) {
        this.companyField.removeValidators(maxLengthCompanyValidator);
      }

      if (maxLengthCompany) {
        maxLengthCompanyValidator = Validators.maxLength(maxLengthCompany);
        this.companyField.addValidators(maxLengthCompanyValidator);
      }
      this.companyField.updateValueAndValidity({ onlySelf: true });

      if (maxLengthLine2Validator) {
        this.line2Field.removeValidators(maxLengthLine2Validator);
      }

      if (maxLengthLine2) {
        maxLengthLine2Validator = Validators.maxLength(maxLengthLine2);
        this.line2Field.addValidators(maxLengthLine2Validator);
      }
      this.line2Field.updateValueAndValidity({ onlySelf: true });

      if (maxLengthLocalityValidator) {
        this.localityField.removeValidators(maxLengthLocalityValidator);
      }
      if (maxLengthLocality) {
        maxLengthLocalityValidator = Validators.maxLength(maxLengthLocality);
        this.localityField.addValidators(maxLengthLocalityValidator);
      }
      this.localityField.updateValueAndValidity({ onlySelf: true });
    });
    effect(() => {
      if (this.required()) {
        this.nameField?.addValidators([Validators.required]);
        this.line1Field?.addValidators([Validators.required]);
        this.localityField?.addValidators([Validators.required]);
        this.countryField?.addValidators([Validators.required]);
        this.postalCodeField?.addValidators([Validators.required]);
        this.regionField?.addValidators([Validators.required]);
      } else {
        this.nameField?.removeValidators([Validators.required]);
        this.line1Field?.removeValidators([Validators.required]);
        this.localityField?.removeValidators([Validators.required]);
        this.countryField?.removeValidators([Validators.required]);
        this.postalCodeField?.removeValidators([Validators.required]);
        this.regionField?.removeValidators([Validators.required]);
      }
      this.nameField?.updateValueAndValidity({ onlySelf: true });
      this.line1Field?.updateValueAndValidity({ onlySelf: true });
      this.localityField?.updateValueAndValidity({ onlySelf: true });
      this.countryField?.updateValueAndValidity({ onlySelf: true });
      this.postalCodeField?.updateValueAndValidity({ onlySelf: true });
      this.regionField?.updateValueAndValidity({ onlySelf: true });
      this.markForCheck();
    });
    effect(() => {
      this.#country();
      const postalCodeControl = this.control()?.controls?.postalCode;
      const regionControl = this.control()?.controls?.postalCode;
      if (postalCodeControl?.value) {
        postalCodeControl?.markAsTouched();
      }
      regionControl?.markAsTouched();

      postalCodeControl?.updateValueAndValidity({ onlySelf: true });
      regionControl?.updateValueAndValidity({ onlySelf: true });
      this.control()?.updateValueAndValidity({ onlySelf: true });
    });
    effect(() => {
      if (this.areCoordinatesRequired()) {
        this.latitudeField?.addValidators([Validators.required]);
        this.longitudeField?.addValidators([Validators.required]);
      } else {
        this.latitudeField?.removeValidators([Validators.required]);
        this.longitudeField?.removeValidators([Validators.required]);
      }
      this.latitudeField?.updateValueAndValidity({ onlySelf: true });
      this.longitudeField?.updateValueAndValidity({ onlySelf: true });
      this.markForCheck();
    });
  }

  protected readonly postalCodeLabel = computed(() => {
    const value = this.valueChanges();
    return !this.empty() ? this.#addressService.getPostalCodeLabel(value?.country ?? '') : 'Postal Code';
  });

  protected readonly postalCodePlaceholder = computed(() => {
    const value = this.valueChanges();
    return !this.empty() ? this.#addressService.getPostalCodePlaceholder(value?.country ?? '') : this.postalCodeLabel();
  });

  protected readonly regionLabel = computed(() => {
    const value = this.valueChanges();
    return !this.empty() ? this.#addressService.getRegionLabel(value?.country ?? '') : 'Province';
  });

  protected readonly regionPlaceholder = computed(() => {
    const value = this.valueChanges();
    return !this.empty() ? this.#addressService.getRegionPlaceholder(value?.country ?? '') : this.regionLabel();
  });

  protected readonly areCoordinatesRequired = computed(() =>
    !this.coordinatesEnabled() ? false : !this.required() ? false : (this.coordinatesRequired() ?? false),
  );

  protected readonly coordinateFieldsHidden = computed(() => {
    return !this.coordinatesEnabled() || (!this.areCoordinatesRequired() ? this.coordinatesHidden() : false);
  });
}
