<div toolbar>
  <div start></div>
  <div end>
    <button mat-flat-button color="primary" (click)="onNewClicked()" [disabled]="open()">{{ newLabel() }}</button>
  </div>
</div>

<ideal-dialog [displayInline]="displayInline()" [(open)]="open">
  <ng-template #content>
    <ideal-form #form [formGroup]="formGroup()" [submitLabel]="submitLabel()">
      <ng-container *ngTemplateOutlet="editTemplate()"></ng-container>
      <button mat-button reset-button type="reset">{{ cancelLabel() }}</button>
    </ideal-form>
  </ng-template>
</ideal-dialog>
