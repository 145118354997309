@let ctrl = control();

<mat-form-field>
  <mat-label>{{ label() }}</mat-label>
  <input
    #field
    matInput
    type="text"
    [formControl]="ctrl"
    [name]="fieldName()"
    [autocomplete]="autoComplete()"
    [maxlength]="maxLengthRestriction()"
    [placeholder]="placeholder()"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (input)="onInput($event)"
    (keypress)="onKeypress($event)"
  />
  @if (ctrl.hasError("required")) {
    <mat-error>{{ label() }} is required</mat-error>
  }

  @let charWhitelistErr = ctrl.getError("characterWhitelist");
  @if (charWhitelistErr) {
    <mat-error>{{ charWhitelistErr.errorMessage }}</mat-error>
  }
  @let charBlacklistErr = ctrl.getError("characterBlacklist");
  @if (charBlacklistErr) {
    <mat-error>{{ charBlacklistErr.errorMessage }}</mat-error>
  }

  @if (ctrl.hasError("matches-control")) {
    <mat-error>
      @let err = ctrl.getError("matches-control");
      @if (err.label) {
        {{ label() }} must match {{ err.label }}
      } @else {
        {{ label() }} must match
      }
    </mat-error>
  }

  @let minErr = ctrl.getError("minlength");
  @let maxErr = ctrl.getError("maxlength");
  <mat-hint align="start" [class.invalid]="minErr || maxErr">{{ lengthHint }}</mat-hint>
  <mat-hint align="end" [class.invalid]="minErr || maxErr">{{ charCountHint }}</mat-hint>

  <!-- hint content projection -->
  <ng-content select="mat-hint:not([align='end'])" ngProjectAs="mat-hint:not([align='end'])"></ng-content>
  <ng-content select="mat-hint[align='end']" ngProjectAs="mat-hint[align='end']"></ng-content>

  <!-- prefix and suffix content projection -->
  <ng-content select="[matPrefix]" ngProjectAs="[matPrefix]"></ng-content>
  <ng-content select="[matIconPrefix]" ngProjectAs="[matIconPrefix]"></ng-content>
  <ng-content select="[matSuffix]" ngProjectAs="[matSuffix]"></ng-content>
  <ng-content select="[matIconSuffix]" ngProjectAs="[matIconSuffix]"></ng-content>
  <ng-content select="[matTextPrefix]" ngProjectAs="[matTextPrefix]"></ng-content>
  <ng-content select="[matTextSuffix]" ngProjectAs="[matTextSuffix]"></ng-content>

  <!-- error content projection -->
  <ng-content select="mat-error" ngProjectAs="mat-error"></ng-content>
  <ng-content select="[matError]" ngProjectAs="[matError]"></ng-content>
  <!-- this makes content projection for errors work -->
  @if (hasContentErrors()) {
    <mat-error></mat-error>
  }
</mat-form-field>

<!-- <pre>
  value: {{ ctrl.value | json }}
  errors: {{ ctrl.errors | json }}
</pre> -->
