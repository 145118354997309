@let ctrl = control();

<input #hiddenField [id]="fieldName()" [name]="fieldName()" type="password" style="display: none" [autocomplete]="autoComplete()" />
<mat-form-field [floatLabel]="floatLabel()">
  <mat-label>{{ label() }}</mat-label>
  <input
    #field
    matInput
    xtypex="passwordHidden() ? 'password' : 'text'"
    [class.show-password]="!passwordHidden()"
    [type]="isPassField() ? 'password' : 'text'"
    [formControl]="ctrl"
    autocomplete="one-time-code"
    [maxlength]="maxLengthRestriction()"
    [placeholder]="placeholder()"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (input)="onInput($event)"
    (keypress)="onKeypress($event)"
  />

  @if (passwordVisibility() === "down") {
    <button
      type="button"
      matSuffix
      mat-icon-button
      (pointerdown)="passwordHidden.set(false); floatLabel.set('always')"
      (pointerup)="passwordHidden.set(true); floatLabel.set('auto')"
      class="pass-vis-btn"
    >
      <mat-icon>{{ passwordHidden() ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
  } @else if (passwordVisibility() === "toggle") {
    <button
      type="button"
      matSuffix
      mat-icon-button
      (click)="passwordHidden.set(!passwordHidden())"
      (pointerdown)="floatLabel.set('always')"
      (pointerup)="floatLabel.set('auto')"
      class="pass-vis-btn"
    >
      <mat-icon>{{ passwordHidden() ? "visibility_off" : "visibility" }}</mat-icon>
    </button>
  }
  <div cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin" (sizeChange)="size.set($event)"></div>

  @if (ctrl.hasError("required")) {
    <mat-error>{{ label() }} is required</mat-error>
  }

  @if (ctrl.hasError("matches-control")) {
    <mat-error>
      @let err = ctrl.getError("matches-control");
      @if (err.label) {
        {{ label() }} must match {{ err.label }}
      } @else {
        {{ label() }} must match
      }
    </mat-error>
  }

  @let charWhitelistErr = ctrl.getError("characterWhitelist");
  @if (charWhitelistErr) {
    <mat-error>{{ charWhitelistErr.errorMessage }}</mat-error>
  }
  @let charBlacklistErr = ctrl.getError("characterBlacklist");
  @if (charBlacklistErr) {
    <mat-error>{{ charBlacklistErr.errorMessage }}</mat-error>
  }

  @let minErr = ctrl.getError("minlength");
  @let maxErr = ctrl.getError("maxlength");
  <mat-hint align="start" [class.invalid]="minErr || maxErr">{{ lengthHint }}</mat-hint>
  <mat-hint align="end" [class.invalid]="minErr || maxErr">{{ charCountHint }}</mat-hint>

  <!-- hint content projection -->
  <ng-content select="mat-hint:not([align='end'])" ngProjectAs="mat-hint:not([align='end'])"></ng-content>
  <ng-content select="mat-hint[align='end']" ngProjectAs="mat-hint[align='end']"></ng-content>

  <!-- prefix and suffix content projection -->
  <ng-content select="[matPrefix]" ngProjectAs="[matPrefix]"></ng-content>
  <ng-content select="[matIconPrefix]" ngProjectAs="[matIconPrefix]"></ng-content>
  <ng-content select="[matSuffix]" ngProjectAs="[matSuffix]"></ng-content>
  <ng-content select="[matIconSuffix]" ngProjectAs="[matIconSuffix]"></ng-content>
  <ng-content select="[matTextPrefix]" ngProjectAs="[matTextPrefix]"></ng-content>
  <ng-content select="[matTextSuffix]" ngProjectAs="[matTextSuffix]"></ng-content>

  <!-- error content projection -->
  <ng-content select="mat-error" ngProjectAs="mat-error"></ng-content>
  <ng-content select="[matError]" ngProjectAs="[matError]"></ng-content>
  <!-- this makes content projection for errors work -->
  @if (hasContentErrors()) {
    <mat-error></mat-error>
  }
</mat-form-field>

<ng-template
  #connectedOverlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="hasFeedbackValidators() && focused()"
>
  @let strength = strengthFeedback();
  @let score = scoreFeedback();
  @let suggestions = suggestionFeedback();
  @let rules = ruleFeedback();

  <div feedback-container [style.width.px]="size().width">
    <div feedback-dialog>
      <div feedback-list>
        @if (strength) {
          <ng-container *ngTemplateOutlet="FeedbackListItem; context: { $implicit: strength, class: 'strength large' }"></ng-container>
        }
        @if (suggestions) {
          <ng-container *ngTemplateOutlet="FeedbackListItem; context: { $implicit: suggestions, class: 'suggestions' }"></ng-container>
        }
        @if (score && ctrl.value) {
          <ng-container *ngTemplateOutlet="FeedbackListItem; context: { $implicit: score, class: 'strength' }"></ng-container>
        }
        @for (rule of rules; track rule.type) {
          <ng-container *ngTemplateOutlet="FeedbackListItem; context: { $implicit: rule, class: 'rule' }"></ng-container>
        }
      </div>
      <!-- <pre>{{ analysis() | json }}</pre> -->
    </div>
  </div>
</ng-template>
<ng-template #FeedbackListItem let-item let-class="class">
  @let score = item.extra?.actualStrength ?? -2;
  <div feedback-item [attr.type]="item.type" [attr.result]="item.result" [attr.strength]="score" [class]="class">
    <div icon-container>
      <div icon>
        <mat-icon class="error">close</mat-icon>
        <mat-icon class="warning">warning</mat-icon>
        <mat-icon class="ok">check</mat-icon>
      </div>
    </div>
    <div content>
      <div message>
        {{ item.message }}
      </div>
      @if (item.type === "strength" && score >= -1) {
        <mat-progress-bar mode="determinate" [value]="(score + 2) * 16.6667"></mat-progress-bar>
      }
      @if (item.suggestions?.length) {
        <div suggestions>
          @for (suggestion of item.suggestions; track suggestion) {
            <div suggestion>{{ suggestion }}</div>
          }
        </div>
      }
    </div>
  </div>
</ng-template>
