import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ideal-form-button-group',
  standalone: true,
  imports: [],
  templateUrl: './form-button-group.component.html',
  styleUrl: './form-button-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormButtonGroupComponent {}
