import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { outputFromObservable, takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AddressService } from 'forms-data';
import { GooglePlacesPlacePrediction, GooglePlacesService } from 'google-data';
import { Subject, debounceTime, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'ideal-address-search',
  standalone: true,
  imports: [MatFormFieldModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './address-search.component.html',
  styleUrl: './address-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSearchComponent {
  readonly #placesService = inject(GooglePlacesService);
  readonly #addressService = inject(AddressService);

  readonly value = model<string>('');
  readonly disabled = input<boolean>(false);

  readonly #addressSuggestions$ = toObservable(this.value).pipe(
    debounceTime(300),
    switchMap((search) => (!search ? of(null) : this.#placesService.autocomplete(search, this.#addressService.getCountryCodes()))),
    map((r) => (!r?.suggestions ? [] : r.suggestions.map((s) => s.placePrediction))),
    takeUntilDestroyed(),
  );

  protected readonly suggestions = toSignal(this.#addressSuggestions$, { initialValue: [] });

  #selectedPlace$$ = new Subject<GooglePlacesPlacePrediction>();
  #selectedPlaceDetails$ = this.#selectedPlace$$.pipe(
    switchMap((place) => (!place ? of(null) : this.#placesService.details(place.placeId))),
    takeUntilDestroyed(),
  );
  #selectedAddress$ = this.#selectedPlaceDetails$.pipe(
    map((r) => this.#addressService.placeToAddress(r)),
    takeUntilDestroyed(),
  );

  readonly addressSelected = outputFromObservable(this.#selectedAddress$);

  protected searchUpdated(search: string): void {
    this.value.set(search.trim());
  }

  protected displayFn(index: number): string {
    return this.suggestions()[index]?.text.text ?? '';
  }

  protected onOptionSelected(evt: MatAutocompleteSelectedEvent) {
    this.#selectedPlace$$.next(this.suggestions()[evt.option.value]);
  }
}
