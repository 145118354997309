import { DecimalPipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, ElementRef, input, viewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  CharactersAllowedResult,
  checkValidCharacters,
  DefaultTextFormControlOptions,
  TextFormControl,
  TextFormControlOptions,
} from 'forms-data';
import { provideValueAccessor } from '../../old/base';
import { AbstractFormField } from '../base';

@Component({
  selector: 'ideal-text-input',
  standalone: true,
  imports: [MatInputModule, MatFormFieldModule, ReactiveFormsModule, DecimalPipe, JsonPipe],
  templateUrl: './text-input.component.html',
  styleUrls: ['../base/form-field-base.scss', './text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(TextInputComponent, false)],
})
export class TextInputComponent<TControl extends TextFormControl = TextFormControl> extends AbstractFormField<string, TControl> {
  public readonly label = input<string>('');
  public readonly placeholder = input<string>('');
  public readonly field = viewChild.required<ElementRef<HTMLInputElement>>('field');

  protected readonly options = computed((): Required<TextFormControlOptions> => {
    return this.control().options ?? { ...DefaultTextFormControlOptions };
  });

  protected readonly maxLengthRestriction = computed(() => {
    const options = this.options();
    return options.lengthValidationStrategy === 'restrict' && (options.maxLength ?? 0) > 0 ? options.maxLength : null;
  });

  protected override createDefaultControl(): TControl {
    return new TextFormControl('') as TControl;
  }

  protected get lengthHint(): string {
    const control = this.control();
    const options = this.options();
    const min = options.minLength;
    const max = options.maxLength;
    const value = control.value ?? '';
    const len = value.length;

    if (min && max) {
      return len < min ? `Min. ${min} characters` : `Max. ${max} characters`;
    } else if (min) {
      return len <= min ? `Min. ${min} characters` : '';
    } else if (max) {
      return `Max. ${max} characters`;
    }
    return '';
  }

  protected get charCountHint(): string {
    const control = this.control();
    const options = this.options();
    const min = options.minLength;
    const max = options.maxLength;
    const value = control.value ?? '';
    const len = value.length;

    if (min && max) {
      return len < min ? `${len}/${min}` : `${len}/${max}`;
    } else if (min) {
      return len <= min ? `${len}/${min}` : '';
    } else if (max) {
      return `${len}/${max}`;
    }
    return '';
  }

  protected onFocus(_: FocusEvent): void {}

  protected onBlur(_: FocusEvent): void {
    this.setViewModel(this.control().value);
  }

  protected onKeypress(evt: KeyboardEvent): void {
    const opts = this.options();
    if (opts.characterValidationStrategy !== 'restrict') return;

    if (evt.key.length === 1) {
      const check = checkValidCharacters(evt.key, opts?.charactersAllowed, opts?.characterDenied);
      console.log('onKeypress', `'${evt.key}'`, check);
      if (check !== CharactersAllowedResult.Valid) {
        console.log(`'${evt.key}' is not allowed`);
        evt.preventDefault();
      }
    }
  }

  protected onInput(evt: Event): void {
    const opts = this.options();
    if (opts.characterValidationStrategy !== 'restrict') return;
    if (evt instanceof InputEvent) {
      switch (evt.inputType) {
        case 'insertFromPaste':
        case 'deleteByCut':
        case 'deleteContentBackward':
          const cleanStr = this.stripInvalidCharacters(this.field().nativeElement.value);
          this.setViewModel(cleanStr);
          this.setInputModel(cleanStr);
          this.setModel(cleanStr);
          break;
      }
    }
  }

  private stripInvalidCharacters(value: string): string {
    const opts = this.options();

    if (opts.charactersAllowed || opts.characterDenied) {
      let newValue = value;
      for (let i = 0; i < value.length; i++) {
        const char = value[i];
        const check = checkValidCharacters(char, opts.charactersAllowed, opts.characterDenied);
        if (check !== CharactersAllowedResult.Valid) {
          newValue = newValue.replaceAll(char, '');
        }
      }
      return newValue;
    }
    return value;
  }

  // fix for form reset bug that causes fields to go blank after form reset
  protected override setViewModel(value?: string | undefined): void {
    super.setViewModel(value);
    const field = this.field().nativeElement;
    if (field.value !== value) {
      field.value = value ?? '';
    }
    setTimeout(() => {
      if (field.value !== value) {
        field.value = value ?? '';
      }
    });
  }
}
