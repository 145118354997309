import { CdkConnectedOverlay, CdkScrollable, OverlayModule } from '@angular/cdk/overlay';
import { DecimalPipe, JsonPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { elementSize, Size } from '@cpangular/rxjs';
import { ElementResizeDirective } from 'common-util';
import { DefaultPasswordFormControlOptions, PasswordFormControl, PasswordFormControlOptions } from 'forms-data';

import { Subscription } from 'rxjs';
import { FormComponent } from '../../common';
import { provideValueAccessor } from '../../old/base';
import { TextInputComponent } from '../text-input';

export enum PasswordVisibilityBehaviors {
  Never = 'never',
  Always = 'always',
  Down = 'down',
  Toggle = 'toggle',
}

export type PasswordVisibilityBehavior = `${PasswordVisibilityBehaviors}`;

@Component({
  selector: 'ideal-password-input',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    DecimalPipe,
    MatIconModule,
    MatButtonModule,
    OverlayModule,
    ElementResizeDirective,
    NgTemplateOutlet,
    MatProgressBarModule,
    JsonPipe,
  ],
  templateUrl: './password-input.component.html',
  styleUrls: ['../base/form-field-base.scss', './password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(PasswordInputComponent, false)],
})
export class PasswordInputComponent extends TextInputComponent<PasswordFormControl> {
  private readonly _cdkScrollable = inject(CdkScrollable);
  // private readonly _router = inject(Router);
  private readonly _formComponent = inject(FormComponent, { optional: true });
  // private readonly _routerEvents$ = this._router.events;

  public readonly passwordVisibility = input<PasswordVisibilityBehavior>(PasswordVisibilityBehaviors.Toggle);

  protected readonly focused = signal<boolean>(false);
  protected readonly passwordHidden = signal<boolean>(true);
  protected readonly floatLabel = signal<'auto' | 'always'>('auto');
  protected readonly size = signal<Size>({ width: 0, height: 0 });
  protected readonly connectedOverlay = viewChild<CdkConnectedOverlay>('connectedOverlay');
  protected readonly hiddenField = viewChild.required<ElementRef<HTMLInputElement>>('hiddenField');

  protected override readonly options = computed(
    (): Required<PasswordFormControlOptions> =>
      (this.control().options ?? { ...DefaultPasswordFormControlOptions }) as Required<PasswordFormControlOptions>,
  );
  protected readonly analysis = computed(() => this.control().analysis?.());
  protected readonly hasFeedbackValidators = computed(() => this.control().hasFeedbackValidators);

  protected isPassField = signal(false);

  // protected autoCompleteSafe = computed(() => {
  //   const ac = this.autoComplete();

  //   return ac;
  // });

  protected ruleFeedback = computed(() => this.analysis()?.feedback.filter((f) => f.type !== 'suggestions' && f.type !== 'strength'));
  protected suggestionFeedback = computed(() => this.analysis()?.feedback.find((f) => f.type === 'suggestions'));
  protected scoreFeedback = computed(() => {
    const scoreFeedBack = this.analysis()?.feedback.find((f) => f.type === 'strength');

    return scoreFeedBack
      ? {
          ...scoreFeedBack,
          type: 'score',
          extra: {
            ...scoreFeedBack.extra,
            actualStrength: -2,
          },
        }
      : null;
  });

  protected strengthFeedback = computed(() => {
    const analysis = this.analysis();
    const strFeedBack = analysis?.feedback.find((f) => f.type === 'strength');
    const value = this.control().value;
    let result = strFeedBack?.result ?? 'error';

    let score = (strFeedBack?.extra?.['actualStrength'] as number) ?? -1;
    let message = $localize`:@@password.strength.invalid.label:Invalid Password`;
    switch (score) {
      case 0:
        message = $localize`:@@password.strength.very-weak.label:Very Weak Password`;
        break;
      case 1:
        message = $localize`:@@password.strength.weak.label:Weak Password`;
        break;
      case 2:
        message = $localize`:@@password.strength.fair.label:Fair Password`;
        break;
      case 3:
        message = $localize`:@@password.strength.good.label:Good Password`;
        break;
      case 4:
        message = $localize`:@@password.strength.strong.label:Strong Password`;
        break;
    }
    if (!value) {
      message = $localize`:@@password.strength.empty.label:Password is required`;
      result = 'error';
    }

    if (result === 'ok' && score < 0) {
      result = 'error';
    }

    console.log('strengthFeedback', score, message, result);
    return strFeedBack
      ? {
          ...strFeedBack,
          message,
          result,
        }
      : null;
  });

  protected override createDefaultControl(): PasswordFormControl {
    return new PasswordFormControl('');
  }

  constructor() {
    super();
    effect(
      () => {
        this.passwordHidden.set(this.passwordVisibility() !== PasswordVisibilityBehaviors.Always);
      },
      { allowSignalWrites: true },
    );
    elementSize(this._cdkScrollable.getElementRef().nativeElement)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.connectedOverlay()?.overlayRef?.updatePosition();
      });
    effect((cleanup) => {
      if (this._formComponent) {
        const sub = new Subscription();
        sub.add(
          this._formComponent.beforeSubmit.subscribe((evt) => {
            // console.log('beforeSubmit', evt);
            this.isPassField.set(true);
            setTimeout(() => {
              this.isPassField.set(false);
              this.hiddenField().nativeElement.value = '';
            }, 100);
            this.hiddenField().nativeElement.value = this.control().value;
          }),
        );
        sub.add(
          this._formComponent.formReset.subscribe((evt) => {
            // console.log('formReset', evt);
            this.hiddenField().nativeElement.value = '';
            this.isPassField.set(false);
          }),
        );
        cleanup(() => sub.unsubscribe());
      }
    });
    // fromEvent(window, 'beforeunload')
    //   .pipe(takeUntilDestroyed())
    //   .subscribe(() => {
    //     //prevents browser from prompting to save password on refresh
    //     this.field().nativeElement.setAttribute('autocomplete', 'one-time-code');
    //   });
    // console.log('formComponent', this._formComponent);
    // effect((cleanup) => {
    //   if (this._formComponent) {
    //     const sub = this._formComponent.beforeSubmit.subscribe((evt) => {
    //       this.field().nativeElement.setAttribute('autocomplete', this.autoComplete());
    //       this.autoCompleteSafe.set(this.autoComplete());
    //       setTimeout(() => {
    //         this.field().nativeElement.setAttribute('autocomplete', 'one-time-code');
    //         this.autoCompleteSafe.set('one-time-code');
    //       }, 10);
    //     });
    //     cleanup(() => sub.unsubscribe());
    //   }
    // });
    // this._formComponent
    // effect((cleanup) => {
    //   const control = this.control();
    //   let parent = control.parent;
    //   let root: FormGroup | FormArray | null = null;
    //   while (parent) {
    //     root = parent;
    //     parent = root.parent;
    //   }

    //   if (root) {
    //     const sub = this.control()
    //       .events.pipe(filter((e) => e instanceof FormSubmittedEvent))
    //       .subscribe((evt) => {
    //         console.log('form evt', evt);
    //         // this.autoCompleteSafe.set(this.autoComplete());
    //       });
    //     cleanup(() => sub.unsubscribe());
    //   }
    //   console.log('root', root);
    //   // console.log('parent', parent);
    // });

    // this._routerEvents$.pipe(filter(e => e instanceof RoutesRecognized),takeUntilDestroyed()).subscribe((evt) => {
    //   console.log('router event', evt);
    //   this.field().nativeElement.setAttribute('autocomplete', 'one-time-code');
    // });
  }

  ngOnDestroy(): void {
    // //prevents browser from prompting to save password on navigation
    // this.field().nativeElement.value = '';
    // this.field().nativeElement.setAttribute('autocomplete', 'one-time-code');
    // this.field().nativeElement.setAttribute('type', 'text');
  }

  protected override onFocus(_: FocusEvent): void {
    super.onFocus(_);
    this.focused.set(true);
    // this.autoCompleteSafe.set(this.autoComplete());

    const fieldElm = this.field().nativeElement;
    const scrollElm = this._cdkScrollable.getElementRef().nativeElement;
    const fieldRect = fieldElm.getBoundingClientRect();

    const target = 128;
    const diff = fieldRect.top - target;
    scrollElm.scrollBy({ behavior: 'smooth', top: diff });
  }
  protected override onBlur(_: FocusEvent): void {
    super.onBlur(_);
    this.focused.set(false);
    // this.autoCompleteSafe.set('one-time-code');
  }
}
