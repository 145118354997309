@if (control(); as control) {
  <mat-select [formControl]="control" [placeholder]="placeholder() ?? ''">
    @if (placeholder()) {
      <mat-option [value]="null">- {{ placeholder() ?? "" }} -</mat-option>
    }

    @for (country of countries(); track country.code) {
      <mat-option [value]="country.code">{{ country.name }}</mat-option>
    }
  </mat-select>
} @else {
  control missing
}
