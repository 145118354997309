import { Component, computed, effect, inject, input, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validator } from '@angular/forms';
import { MatOption, MatSelect } from '@angular/material/select';
import { AddressService } from 'forms-data';
import { AbstractFormControl, MatFormFieldAdaptorDirective } from '../../base';
import { provideValueAccessor } from '../../base/provideValueAccessor';

@Component({
  selector: 'ideal-region-select',
  standalone: true,
  imports: [MatSelect, MatOption, ReactiveFormsModule],
  templateUrl: './region-select.component.html',
  styleUrl: './region-select.component.scss',
  providers: [provideValueAccessor(RegionSelectComponent)],
  hostDirectives: [MatFormFieldAdaptorDirective],
})
export class RegionSelectComponent extends AbstractFormControl<string | null> implements Validator {
  public controlType = 'region-select';

  readonly #addressService = inject(AddressService);

  readonly placeholder = input<string | undefined>(undefined);
  readonly shouldLabelFloat = signal(true);
  readonly country = input.required<string | null | undefined>();

  protected readonly regions = computed(() => {
    const country = this.country();
    if (!country) return [];
    return this.#addressService.getRegionCodes(country).map((code) => ({
      code,
      name: this.#addressService.getRegionName(country, code),
    }));
  });

  constructor() {
    super();
    this.setControl(new FormControl<string | null>(null));
    effect(() => {
      const country = this.country();
      if (!country || !this.regions().find((region) => region.code === this.value)) {
        this.writeValue(null);
      }
      this.ngControl?.control?.updateValueAndValidity();
    });
  }
}
