@if (control(); as control) {
  <mat-select [formControl]="control" [placeholder]="placeholder() ?? ''">
    @if (placeholder()) {
      <mat-option [value]="null">- {{ placeholder() ?? "" }} -</mat-option>
    }
    @for (region of regions(); track region.code) {
      <mat-option [value]="region.code">{{ region.name }}</mat-option>
    }
  </mat-select>
}
